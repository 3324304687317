import request from "./request";
import type {
  ICreation,
  ICredit,
  DetectData,
  Face,
  GcsSign,
  Material,
  IPayment,
  Product,
  FaceSwapRes,
  Tool,
  UserInfo,
  PaymentMethod,
} from "~/types";
class Api {
  async userinfo(): Promise<UserInfo> {
    return request.post("/my/userinfo");
  }

  // 获取产品列表
  async getProducts(type: "sub" | "one-time" = "sub"): Promise<Product[]> {
    return request.post("/payment/products", { type });
  }

  // 购买商品
  async submitOrder(
    product: Product,
    method: PaymentMethod
  ): Promise<{ url: string }> {
    let requestUrl = "";
    const requestBody: Record<string, any> = { product_id: product.product_id };

    // 根据不同的支付方式设置请求 URL 和请求体
    switch (method) {
      case "crypto":
        requestUrl = "/nowpayments/buy";
        break;

      case "skrill":
      case "trustly":
        requestUrl = "/payssion/buy";
        requestBody.payment_method = method; // 在请求体中添加 method 参数
        break;

      case "stripe":
      default:
        requestUrl = "/stripe/buy";
        break;
    }

    // 发起 POST 请求并返回结果
    return request.post(requestUrl, requestBody);
  }

  // /paypal/verify_payment 验证支付状态
  async verifyPayment(
    paymentId: string,
    type: "one-time" | "sub"
  ): Promise<{ payment_id: string; payment_status: number }> {
    return request.post("/payment/verify", { id: paymentId, type });
  }

  // 支付历史记录
  async getPaymentList(
    page: number = 1,
    perPage: number = 20
  ): Promise<IPayment[]> {
    return request.post("/my/payment_lists", { page, per_page: perPage });
  }

  // 积分历史记录
  async getCreditList(
    page: number = 1,
    perPage: number = 20
  ): Promise<ICredit[]> {
    return request.post("/my/credit_lists", { page, per_page: perPage });
  }

  // 人脸列表
  async getFaceList(page: number = 1, perPage: number = 20): Promise<Face[]> {
    return request.post("/my/faces", { page, per_page: perPage });
  }

  // 置顶人脸
  async topedFace(faceId: string): Promise<null> {
    return request.post("/toped/face", { face_id: faceId });
  }

  // 删除人脸
  async deleteFace(faceId: string): Promise<null> {
    return request.post("/del/face", { face_id: faceId });
  }

  // 上传人脸
  async uploadFace(photo: File) {
    const formData = new FormData();
    formData.append("photo", photo);

    return request.post("/upload/face", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  // 获取签名 url format support: 'png' | 'jpg' | 'jpeg' | 'mp4'
  async getSignUrl(format: string): Promise<GcsSign> {
    return request.post("/upload/sign_url", { format });
  }

  // 我的素材
  async getMyUploads(
    type: string = "all",
    page: number = 1,
    perPage: number = 20
  ): Promise<{ lists: Material[]; total: number }> {
    return request.post("/my/uploads", { type, page, per_page: perPage });
  }

  // 删除素材
  async deleteMat(materialId) {
    return request.post("/del/material", { material_id: materialId });
  }

  // 我的创作
  async getMyCreations(
    type: string = "all",
    page: number = 1,
    perPage: number = 20
  ): Promise<{ lists: ICreation[]; total: number }> {
    return request.post("/my/creates", { type, page, per_page: perPage });
  }

  // 提交人脸识别
  async uploadDetectCreate(
    materialId: string
  ): Promise<{ material_id: string; task_id: string }> {
    const data: { material_id: string } = { material_id: materialId };
    return request.post("/upload/detect_create", data);
  }

  // 获取人脸识别结果
  async uploadDetectResult(
    materialId: string,
    taskId?: string
  ): Promise<DetectData> {
    const data: { material_id: string; task_id?: string } = {
      material_id: materialId,
    };
    if (taskId) {
      data.task_id = taskId;
    }
    return request.post("/upload/detect_result", data);
  }

  // 删除作品
  async deleteCreation(orderId: string) {
    return request.post("/del/order", { order_id: orderId });
  }

  // 提交换脸
  async createFaceSwap(
    materialId: string,
    bindFace: Record<string, number[]>,
    toolId: string
  ): Promise<{ order_id: string; task_id: string; wait_interval: number }> {
    // 格式化为所需字符串形式 {"face_id1": "1,3", "face_id2": "4"}
    const bindFaceParsed: Record<string, string> = Object.entries(
      bindFace
    ).reduce((acc, [key, value]) => {
      acc[key] = value.join(",");
      return acc;
    }, {} as Record<string, string>);

    // 将 bindFaceParsed 转换为 JSON 字符串
    const bindFaceString = JSON.stringify(bindFaceParsed);

    return request.post("/faceswap/create", {
      tool_id: toolId || "P001",
      material_id: materialId,
      bind_face: bindFaceString,
    });
  }

  // 获取换脸结果
  async fetchFaceSwapRes(
    orderId: string,
    taskId: string
  ): Promise<FaceSwapRes> {
    return request.post("/faceswap/result", {
      order_id: orderId,
      task_id: taskId,
    });
  }

  // 通过material获取素材
  async getMaterialDetail(
    materialId: string,
    toolId: string
  ): Promise<Material | null> {
    return request.post("/upload/material_detail", {
      material_id: materialId,
      tool_id: toolId,
    });
  }

  // 通过material获取素材
  async getTools(): Promise<Tool[] | null> {
    return request.post("/tool/lists");
  }

  async oneTapLogin(data: {
    idtoken: string;
    utmAll;
    redirect?: string;
  }): Promise<{ uid: string; token: string }> {
    return request.post("/idtoken_login/google", {
      idtoken: data.idtoken,
      utm_all: data.utmAll,
      redirect: data.redirect,
    });
  }

  // 邮箱注册
  async registerByEmail(
    email: string,
    password: string,
    utmAll?: string
  ): Promise<any> {
    const payload = {
      email,
      password,
      ...(utmAll && { utm_all: utmAll }),
    };
    return request.post("/email/register", payload);
  }
  // 邮箱登录
  async loginByEmail(
    email: string,
    password: string
  ): Promise<{ token: string }> {
    return request.post("/email/login", { email, password });
  }

  // // 修改密码
  // async changePassword(currPwd: string, newPwd: string, newPwdAgain: string): Promise<any> {
  //     return request.post('/email/change_password', {current_password: currPwd, new_password: newPwd, new_password_confirmation: newPwdAgain})
  // }

  // 修改密码接口 - 参数为可选
  async changePassword(payload: {
    current_password?: string;
    new_password: string;
    new_password_confirmation: string;
  }): Promise<any> {
    return request.post("/email/change_password", payload);
  }

  // 激活邮箱 ?
  async emailActivate(code: string): Promise<{ token: string }> {
    return request.get(`/email/activate?code=${code}`);
  }

  // 忘记密码
  async forgotPassword(email: string): Promise<any> {
    return request.post("/email/forgot_password", { email });
  }

  // 忘记密码校验 ?
  async forgotVerify(code: string): Promise<any> {
    return request.get(`/email/forgot_verify?code=${code}`);
  }

  // 重置密码
  async resetPassword(
    code: string,
    newPwd: string,
    newPwdAgain: string
  ): Promise<any> {
    return request.post("/email/reset_password", {
      code,
      new_password: newPwd,
      new_password_confirmation: newPwdAgain,
    });
  }

  // 删除用户帐号
  async deleleAccount(uid: string): Promise<any> {
    return request.post("/del/account", { uid });
  }

  // 提交用户反馈
  async feedback(
    contents: string,
    attachments: string = "",
    email: string = ""
  ): Promise<any> {
    return request.post("/feedback/create", { contents, attachments, email });
  }

  // 上传附件获取签名url
  async attachUpploader(
    format: string
  ): Promise<{ sign_url: string; file_url: string }> {
    return request.post("/feedback/sign_url", { format });
  }
}

const api = new Api();
export default api;
